<script>
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "UpdateDislocation",
  data() {
    return {
      is_updating: false,
      showModal: false
    }
  },
  methods: {
    async updateDislocation() {
      try {
        this.is_updating = true
        await axios.get('/train/update-train-dislocation/')
        this.is_updating = false
        await Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Dislocations updated successfully',
        })
      } catch {
        this.is_updating = false
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    }
  }
}
</script>

<template>
  <b-button @click="showModal = true" variant="primary">
    Update Dislocations
  </b-button>
  <b-modal v-model="showModal" title="Are you sure ?" centered hide-footer>
    <h5 class="mb-4">
      Are you sure you want to update dislocations for all trains ?
    </h5>
    <div class="d-flex gap-3">
      <b-button :disabled="is_updating"
          variant="success" @click="updateDislocation()">
        {{ is_updating ? 'Updating...' : 'Yes, update now' }}
      </b-button>
      <b-button variant="soft-primary" @click="showModal = false">No</b-button>
    </div>
  </b-modal>
</template>

<style scoped>

</style>