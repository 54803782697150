<template>
  <PageHeader :title="title" :items="items"/>
  <TrainStatistics/>
  <div class="mb-4">
    <Table :name="table.name" selectable
           :headers="table_headers"
           :url="table.url" :searchable="table.searchable">

      <template v-slot:name="props">
        <router-link @click="saveToLocalStorage(props.row)"
                     v-if="props.row.name"
                     class="badge badge-soft-primary fs-11"
                     :to="{name: 'train_details_main', params: {train_slug: props.row.slug}}">
          {{ props.row.name }}
        </router-link>
        <span v-else>--</span>
      </template>

      <template v-slot:top-right>
        <UpdateDislocation/>
      </template>

      <template v-slot:type="props">
            <span v-if="props.row.type" class="badge" :class="{
                'badge-outline-primary': props.row.type === 'import',
                'badge-outline-warning': props.row.type === 'export'
            }">
                {{ props.row.type }}
            </span>
        <span v-else>--</span>
      </template>

      <template v-slot:wagon_count="{row: data}">
        <span class="badge" :class="{
          'badge-soft-primary': data.wagon_count,
          'badge-soft-danger': !data.wagon_count,
        }">{{ data.wagon_count || 0 }}</span>
      </template>

      <template v-slot:container_count="{row: data}">
        <span class="badge" :class="{
          'badge-soft-primary': data.container_count,
          'badge-soft-danger': !data.container_count,
        }">{{ data.container_count || 0 }}</span>
      </template>

      <!--      <template v-slot:is_active="props">-->
      <!--            <span v-if="props.row.is_active" class="badge" :class="{-->
      <!--                'badge-outline-success': props.row.is_active === true,-->
      <!--                'badge-outline-danger': props.row.is_active === false-->
      <!--            }">-->
      <!--                {{ props.row.is_active === true ? 'Active' : 'Inactive' }}-->
      <!--            </span>-->
      <!--        <span v-else>&#45;&#45;</span>-->
      <!--      </template>-->

      <template v-slot:status="props">
            <span v-if="props.row.status" class="badge" :class="{
                'badge-outline-warning': props.row.status === 'expecting',
                'badge-outline-primary': props.row.status === 'completed',
                'badge-outline-success': props.row.status === 'dispatched'
            }">
                {{ props.row.status.slice(0, 1).toUpperCase() + props.row.status.slice(1) }}
            </span>
        <span v-else>--</span>
      </template>

      <template v-slot:departure_station="props">
            <span v-if="props.row.departure_station">
                {{ props.row.departure_station.name }}
                 ({{ props.row.departure_station.code }})
            </span>
        <span v-else>--</span>
      </template>

      <template v-slot:destination_station="props">
            <span v-if="props.row.destination_station">
                {{ props.row.destination_station.name }}
                 ({{ props.row.destination_station.code }})
            </span>
        <span v-else>--</span>
      </template>

    </Table>
  </div>
</template>

<script>
import PageHeader from "@/components/page-header.vue"
import Table from "@/components/tables/table.vue";
import TrainStatistics from "@/views/pages/trains/TrainStatistics.vue";
import UpdateDislocation from "@/views/pages/trains/components/UpdateDislocation.vue";

export default {
  name: "trains_list",
  components: {
    PageHeader,
    Table,
    TrainStatistics,
    UpdateDislocation
  },
  data() {
    return {
      title: "Trains",
      items: [
        {
          text: "Dashboards",
          href: "/",
        },
        {
          text: "Trains",
          active: true,
        },
      ],
      table: {
        searchable: true,
        name: 'Trains List',
        url: '/train/list/', // table api url
        headers: [
          {
            field: 'id',
            label: 'ID',
            align: 'center',
            visible: false
          },
          {
            field: 'created',
            label: 'DATE CREATED',
            align: 'center',
            visible: false
          },
          {
            field: 'name',
            label: 'NAME',
            align: 'center',
          },
          {
            field: 'type',
            label: 'TYPE',
            align: 'center',
            searchType: 'select',
            searchOptions: [
              {
                value: '',
                label: 'All'
              },
              {
                value: 'import',
                label: 'Import'
              },
              {
                value: 'export',
                label: 'Export'
              },
              {
                value: 'transit',
                label: 'Transit'
              }
            ]
          },
          {
            field: 'container_count',
            label: 'Containers count',
            align: 'center',
            searchable: false
          },
          {
            field: 'wagon_count',
            label: 'Wagons count',
            align: 'center',
            searchable: false
          },
          {
            field: 'route',
            label: 'ROUTE',
            align: 'center',
          },
          // {
          //   field: 'is_active',
          //   label: 'IS ACTIVE',
          //   align: 'center',
          //   searchType: 'select',
          //   searchOptions: [
          //     {
          //       value: '',
          //       label: 'All'
          //     },
          //     {
          //       value: true,
          //       label: 'Active'
          //     },
          //     {
          //       value: false,
          //       label: 'Inactive'
          //     }
          //   ]
          // },
          {
            field: 'status',
            label: 'STATUS',
            align: 'center',
            searchType: 'select',
            searchOptions: [
              {
                value: '',
                label: 'All'
              },
              {
                value: 'expecting',
                label: 'Expecting'
              },
              {
                value: 'dispatched',
                label: 'Dispatched'
              },
              {
                value: 'completed',
                label: 'Completed'
              },
            ]
          },
          {
            field: 'departure_date',
            label: 'ETD',
            searchType: 'date',
            align: 'center',
          },
          {
            field: 'expected_arrival_date',
            label: 'ETA',
            searchType: 'date',
            align: 'center',
          },
          {
            field: 'departure_station',
            label: 'DEPARTURE STATION',
            align: 'center',
            excel_data: (train) => {
              return train.departure_station ? train.departure_station.name + ' (' + train.departure_station.code + ')' : '-'
            }
          },
          {
            field: 'destination_station',
            label: 'DESTINATION STATION',
            align: 'center',
            excel_data: (train) => {
              return train.destination_station ? train.destination_station.name + ' (' + train.destination_station.code + ')' : '-'
            }
          },
          {
            field: 'description',
            label: 'DESCRIPTION',
            align: 'center',
            visible: false
          }
        ],
      }
    };
  },
  computed: {
    table_headers() {
      return this.table.headers
    }
  },
  methods: {
    saveToLocalStorage(train) {
      localStorage.setItem('train', JSON.stringify({
        id: train.id,
        name: train.name,
        eta: train.expected_arrival_date,
        etd: train.departure_date,
        status: train.status,
      }))
    },
  }
}
</script>

<style scoped>

</style>