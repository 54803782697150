<script>
import axios from "axios";
import skeleton from "@/components/custom/skeleton.vue";

export default {
  name: "TrainStatistics",
  data() {
    return {
      stats: {
        status: [],
        type: []
      },
      is_loading: false
    }
  },
  computed: {
    expecting_trains() {
      try {
        return {
          count: this.stats.status.find(stat => stat.status === 'expecting').count || 0,
          icon: 'mdi mdi-clock-time-four-outline',
          color: 'warning',
          ok: true,
        }
      } catch {
        return {
          count: 0,
          icon: 'mdi mdi-clock-time-four-outline',
          ok: false
        }
      }
    },
    dispatched_trains() {
      try {
        return {
          count: this.stats.status.find(stat => stat.status === 'dispatched').count || 0,
          icon: 'mdi mdi-check',
          color: 'primary',
          ok: true
        }
      } catch {
        return {
          count: 0,
          icon: 'mdi mdi-check',
          ok: false
        }
      }
    },
    completed_trains() {
      try {
        return {
          count: this.stats.status.find(stat => stat.status === 'completed').count || 0,
          icon: 'mdi mdi-check-all',
          color: 'success',
          ok: true
        }
      } catch {
        return {
          count: 0,
          icon: 'mdi mdi-check-all',
          ok: false
        }
      }
    }
  },
  components: {skeleton},
  methods: {
    async getTrainStatistics() {
      try {
        this.is_loading = true
        const response = await axios.get(`/train/list/stats/`);
        this.stats = response.status === 200 ? response.data : {
          status: [],
          type: []
        };
        this.is_loading = false
      } catch {
        this.is_loading = false
      }
    }
  },
  created() {
    this.getTrainStatistics()
  }
}
</script>

<template>
  <b-card no-body>
    <b-card-body class="py-0">
      <div class="row row-cols-md-3 row-cols-1" v-if="is_loading">
        <div class="col-lg border-end">
          <div class="mt-3 mt-md-0 py-4 px-3">
            <h5 class="text-muted text-uppercase fs-13">
              <skeleton class_list="w-50 mb-3"/>
              <i class="ri-arrow-down-circle-line text-danger fs-18 float-end align-middle"></i>
            </h5>
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <i :class="expecting_trains.icon" class="display-6 text-muted"></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <h2 class="mb-0">
                  <skeleton class_list="w-50"/>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg border-end">
          <div class="mt-3 mt-lg-0 py-4 px-3">
            <h5 class="text-muted text-uppercase fs-13">
              <skeleton class_list="w-50"/>
              <i class="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i>
            </h5>
            <div class="d-flex align-items-center w-100">
              <div class="flex-shrink-0">
                <i :class="dispatched_trains.icon" class="display-6 text-muted"></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <h2 class="mb-0">
                  <skeleton class_list="w-50"/>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg">
          <div class="mt-3 mt-lg-0 py-4 px-3">
            <h5 class="text-muted text-uppercase fs-13">
              <skeleton class_list="w-50 mb-3"/>
              <i class="ri-arrow-down-circle-line text-danger fs-18 float-end align-middle"></i></h5>
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <i :class="expecting_trains.icon" class="display-6 text-muted"></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <h2 class="mb-0">
                  <skeleton class_list="w-50"/>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-cols-md-3 row-cols-1" v-else-if="!is_loading">
        <div class="col-lg border-end">
          <div class="mt-3 mt-md-0 py-4 px-3">
            <h5 class="text-muted text-uppercase fs-13">
              <span :class="{
                'text-danger' :  expecting_trains.ok === false
              }">
                {{ expecting_trains.ok ? 'Expecting Trains' : 'Something went wrong !' }}
              </span>
            </h5>
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <i
                    :class="expecting_trains.icon + ' text-'+ expecting_trains.color"
                    class="display-6"></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <h2 class="mb-0">
                  {{ expecting_trains.count }}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg border-end">
          <div class="mt-3 mt-lg-0 py-4 px-3">
            <h5 class="text-muted text-uppercase fs-13">
              <span :class="{
                'text-danger' : dispatched_trains.ok === false
              }">
                {{ dispatched_trains.ok ? 'Dispatched Trains' : 'Something went wrong !' }}
              </span>
              <i class="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i>
            </h5>
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <i
                    :class="dispatched_trains.icon + ' text-'+ dispatched_trains.color"
                    class="display-6"></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <h2 class="mb-0">{{ dispatched_trains.count }}</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg">
          <div class="mt-3 mt-lg-0 py-4 px-3"><h5 class="text-muted text-uppercase fs-13">
            <span :class="{
                'text-danger' : completed_trains.ok === false
              }">
                {{ completed_trains.ok ? 'Completed Trains' : 'Something went wrong !' }}
              </span>
            <i class="ri-arrow-down-circle-line text-danger fs-18 float-end align-middle"></i></h5>
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <i
                    :class="completed_trains.icon + ' text-'+ completed_trains.color"
                    class="display-6"></i>
              </div>
              <div class="flex-grow-1 ms-3"><h2 class="mb-0">
                {{ completed_trains.count }}
              </h2></div>
            </div>
          </div>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<style scoped>

</style>